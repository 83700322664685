@import '../../variables.scss';

.buttons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 20px;
  padding: 10px 20px 10px 20px;
  text-decoration: none;
  transition: background 0.1s linear;
  outline: none;
  margin: 10px;
  &.primary {
    color: $light;
    background: $accent;
    border: none;
    &:hover {
      background: $accent2;
    }
  }
  &.inverted {
    color: $accent;
    background: $light;
    border: 2px solid $accent;
    &:hover {
      border: 2px solid $accent2;
    }
  }
}

.visible-columns {
  width: 40%;
  display: flex;
  flex-direction: column;
}
:root {
  --form-control-color: $;
  --form-control-disabled: #959495;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}
.form-control {
  display: flex;
  font-size: 1.2rem;
  padding: 2px;
  box-sizing: border-box;
  cursor: pointer;
  &:hover {
    color: $accent;
  }
}

input[type='checkbox'] {
  appearance: none;
  margin: 0;

  font: inherit;
  color: $accent;
  width: 1.4em;
  height: 1.4em;
  border: 0.07em solid $accent;
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  margin-right: 10px;
  cursor: pointer;

  display: grid;
  place-content: center;
}

input[type='checkbox']::before {
  content: '';
  width: 0.8em;
  height: 0.8em;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 7em 7em $accent;
  /* Windows High Contrast Mode */
  background-color: CanvasText;
}

input[type='checkbox']:checked::before {
  transform: scale(1);
}

.settings-panel {
  position: fixed;
  display: flex;
  left: 15vw;
  top: 10vh;
  background-color: $light;
  width: 70vw;
  z-index: 99;
  padding: 20px;
  border-radius: 7px;
  border: 3px solid $borderColor;
  h2 {
    line-height: 1;
    font-weight: 500;
    font-size: 1.2rem;
    margin: 0 20px 20px 0;
  }
}

.input-params-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

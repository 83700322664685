@import '../../variables.scss';

table * {
  background-color: $primary;
  color: $fontColor;
}
thead * {
  background-color: $secondary;
  color: $light;
}
tr {
  display: flex;
  justify-content: space-between;
  min-width: 100vw;
}
th {
  min-height: 45px;
}

.cell {
  min-width: 120px;
  display: flex;
  border: 1px solid $borderColor;
  box-sizing: border-box;
  width: 100%;
  padding: 2px 4px;
}

.fixed-width {
  width: 120px;
}

@import '../variables.scss';

.settings-button {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: -20px;
  left: 90px;
  cursor: pointer;
  color: $light;
  padding-bottom: 60px;
  background-color: $accent;
  outline: none;
  border: none;
  border-radius: 7px;
  z-index: 999;
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: $accent2;
  }
}

@import '../../variables.scss';

input[type='range'] {
  -webkit-appearance: none;
  margin: 18px 0;
  width: 380px;
  margin-right: 10px;
}
input[type='range']:focus {
  outline: none;
}
.slider.disabled input[type='range']::-webkit-slider-runnable-track {
  background: #999;
}
input[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  background: $accent;
  border-radius: 1.3px;
  border: 0.2px solid #010101;
}
input[type='range']::-webkit-slider-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
  height: 36px;
  width: 16px;
  border-radius: 3px;
  background: #ffffff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -14px;
}
input[type='range']:focus::-webkit-slider-runnable-track {
  background: $accent2;
}
input[type='range']::-moz-range-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  background: $accent2;
  border-radius: 1.3px;
  border: 0.2px solid #010101;
}
input[type='range']::-moz-range-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
  height: 36px;
  width: 16px;
  border-radius: 3px;
  background: #ffffff;
  cursor: pointer;
}
input[type='range']::-ms-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}
input[type='range']::-ms-fill-lower {
  background: $accent;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
input[type='range']::-ms-fill-upper {
  background: $accent2;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
input[type='range']::-ms-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
  height: 36px;
  width: 16px;
  border-radius: 3px;
  background: #ffffff;
  cursor: pointer;
}
input[type='range']:focus::-ms-fill-lower {
  background: $accent2;
}
input[type='range']:focus::-ms-fill-upper {
  background: $accent2;
}

.input-param {
  display: flex;
  justify-content: space-between;
  > h3 {
    width: 28%;
  }
  .slider-label {
    font-size: 0.9em;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-right: 10px;
  }
  .slider {
    width: 70%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2em;
    > span {
      width: 30px;
    }
  }
}

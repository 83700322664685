@import './variables.scss';

html,
body {
  background-color: $primary;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bold {
  font-weight: bold;
}
.center {
  justify-content: center;
  align-items: center;
}
.right {
  justify-content: flex-end;
  align-items: center;
}
.text-right {
  text-align: right;
}
.sticky {
  position: sticky;
  z-index: 1;
}
.top {
  top: 0;
}
.left {
  left: 0;
  z-index: 0;
}

.tanned {
  background-color: $tanned;
}
